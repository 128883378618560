import { jsonToPoint } from '~/helpers/database-conversions'
import { getTranslations } from '~/services/database/translations'
import { Database } from '~/types/supabase'

type Chapter = Database['public']['Tables']['chapters']['Row']
type Page = Database['public']['Tables']['pages']['Row']
type Translation = Database['public']['Tables']['translations']['Row']
export type IndexedTranslation = Database['public']['Tables']['translations']['Row'] & {
  index: number
}

const reduceToLastVersions = (translations: Translation[]) => {
  const reduced: Translation[] = []
  translations.forEach(translation => {
    const alreadyStored = reduced.find(entry => entry.entry_id === translation.entry_id)
    if(!alreadyStored){
      const versions = translations.filter(entry => entry.entry_id === translation.entry_id)
      const sorted = versions.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()).reverse()
      reduced.push(sorted[0])
    }
  })
  return reduced
}

const sortByPosition = (translations: Translation[], pages: Page[]) => {
  const sortedByPosition: Translation[] = []
  pages.sort((a, b) => a.index - b.index).forEach(page => {
    const pageTranslations = translations.filter(entry => entry.page_id === page.id)
    pageTranslations.sort((a, b) => {
      const aPoint = jsonToPoint(a.start_point)
      const bPoint = jsonToPoint(b.start_point)
      const aIsHigher = aPoint.y < bPoint.y ? -2 : 0 // aIsHigher has priority so it returns -2
      const aIsLower = aPoint.y > bPoint.y ? 1 : 0
      const aIsLeftmost = aPoint.x < bPoint.x ? -1 : 1
      return aIsHigher + aIsLower + aIsLeftmost
    })
    sortedByPosition.push(...pageTranslations)
  })
  return sortedByPosition
}

const getChapterTranslations = async (chapterId: Chapter['id'], pages: Page[]) => {
  const translations = await getTranslations(chapterId) 
  const lastVersions = reduceToLastVersions(translations)
  const sortedByPosition = sortByPosition(lastVersions, pages)
  const indexedTranslations = sortedByPosition.map((entry, index) => ({
    ...entry,
    index
  }))
  return indexedTranslations
}

export {
  getChapterTranslations
}