import { BoundingBox } from '~/types/comic/geometry'
import { Rect, FabricText, Group } from 'fabric'
import { colors } from '~/editor/design-tokens'
import { indexRectStyle } from '~/editor/translation/translation'

const getIndexedTranslation = (boundingBox: BoundingBox, index: number) => {
  const container = new Rect({
    width: boundingBox.width,
    height: boundingBox.height,
    fill: 'transparent',
    stroke: colors.box.default,
    strokeWidth: 2,
    opacity: 0.5
  })
  const indexRect = new Rect({
    width: indexRectStyle.width,
    height: indexRectStyle.height,
    fill: colors.box.default,
    opacity: 0.5
  })
  const indexText = new FabricText((index + 1).toString(), {
    top: 6,
    fontWeight: 'bold',
    fontFamily: 'Arial',
    fontSize: 16,
    fill: colors.box.text
  })
  indexText.setX(indexRectStyle.width / 2 - indexText.getScaledWidth() / 2)
  const indexGroup = new Group([
    indexRect,
    indexText
  ], {
    top: -indexRectStyle.height
  })

  const group = new Group([
    container,
    indexGroup
  ], {
    ...boundingBox,
  })
  return group
}

export {
  getIndexedTranslation
}