import { imageLoader } from '~/editor/image/image'
import { IndexedTranslation } from '~/server/export/translation/chapter-translations'
import { getIndexedPage } from '~/server/export/translation/indexed-page'
import { getPagesURLs } from '~/server/export/translation/page-images'
import { Database } from '~/types/supabase'

type Page = Database['public']['Tables']['pages']['Row']

const getIndexedPages = async (translations: IndexedTranslation[], pages: Page[]) => {
  const imagesURLs = await getPagesURLs(pages)
  const imageLoaders = imagesURLs.map(url => imageLoader(url))
  const imagesTags = await Promise.all(imageLoaders)
  const indexedPages = await Promise.all(
    pages.map(async (page, index) => {
      const pageImage = imagesTags[index]
      const pageTranslations = translations.filter(entry => entry.page_id === page.id)
      return await getIndexedPage(pageImage, pageTranslations)
    })
  )
  return indexedPages
}

export {
  getIndexedPages
}