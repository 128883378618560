import FileSaver from 'file-saver'
import JSZip from 'jszip'
import { getChapterTranslations } from '~/server/export/translation/chapter-translations'
import { getIndexedPages } from '~/server/export/translation/indexed-pages'
import { getTXT } from '~/server/export/translation/txt'
import { getPages } from '~/services/database/pages'
import { ExportChapterTranslationPayload } from '~/types/database/chapter'
import { getSeries } from '~/services/database/series'
import { getChapter } from '~/services/database/chapters'

const getTranslationsExport = async (chapterId: ExportChapterTranslationPayload) => {
  const startTime = performance.now()

  const chapter = await getChapter(chapterId)
  const series = await getSeries(chapter.series_id)
  const exportFilename = `${series?.title} - Chapitre ${chapter.index}`
  const pages = (await getPages(chapter.id)).filter(page => page.type === 'original').sort((a, b) => a.index - b.index)
  const translations = await getChapterTranslations(chapter.id, pages)
  const indexedPages = await getIndexedPages(translations, pages)
  const txt = await getTXT(chapter.id, translations)
  const textBlob = new Blob([txt], { type: "text/plain;charset=utf-8" })

  const zip = new JSZip()
  indexedPages.forEach((page, index) => {
    const pageIndex = `${index.toString().length > 1 ? '' : '0'}${index + 1}`
    const rawBase64 = page.dataURL.split('base64,')[1]
    zip.file(`page-${pageIndex}.png`, rawBase64, { base64: true })
  })
  const zipBlob = await zip.generateAsync({ type: "blob" })

  FileSaver.saveAs(textBlob, `${exportFilename} (traductions).txt`)
  FileSaver.saveAs(zipBlob, `${exportFilename} (traductions).zip`)

  const endTime = performance.now()
  const generation_time = (endTime - startTime) / 1000
  console.log({ generation_time })
}

export {
  getTranslationsExport
}